import { IconDoorExit, IconUser } from '@tabler/icons-react'
import Link from 'next/link'
import { env } from '@moo/environment'
import { useUser } from '@moo/identity'
import { Avatar } from '../Avatar'
import { Menu } from '../Menu'

export const UserMenu = () => {
  const { user, error, isLoading } = useUser()

  if (isLoading || error || !user) return null

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Avatar
          src={user.picture ?? '/avatar-placeholder.svg'}
          alt={user.name ?? "user's avatar"}
          size="sm"
        />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Hello {user.name}!</Menu.Label>

        <Menu.Item leftSection={<IconUser />} disabled>
          Profile
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          component={Link}
          color="var(--mantine-palette-messaging-red)"
          leftSection={<IconDoorExit />}
          href={`${env.NEXT_PUBLIC_MAIN_APP_URL}/api/auth/logout`}
        >
          Log out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
