import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import type { Product } from '@moo/types'

type CartStoreState = {
  products: Product[]
  addToCart: (product: Product) => void
  removeFromCart: (productId: string) => void
  drawerOpen: boolean
  openDrawer: () => void
  closeDrawer: () => void
}

export const useCartStore = create<CartStoreState>()(
  devtools(
    persist(
      (set) => ({
        products: [],
        addToCart: (product) =>
          set((state) => ({
            ...state,
            products: [...state.products, product],
          })),
        removeFromCart: (productId) =>
          set((state) => ({
            ...state,
            products: state.products.filter(
              (product) => product.id !== productId
            ),
          })),
        drawerOpen: false,
        openDrawer: () => set((state) => ({ ...state, drawerOpen: true })),
        closeDrawer: () => set((state) => ({ ...state, drawerOpen: false })),
      }),
      {
        name: 'moo-cart-store',
      }
    )
  )
)
