import { rem } from '@mantine/core'
import { palette } from './colours'

const bryantFontStack =
  '"Bryant MOO Pro", "Trebuchet MS", Trebuchet, "Liberation Sans", sans-serif'
const avenirFontStack = '"Avenir Next Rounded Pro", Verdana, Geneva, sans-serif'

export const other = {
  fontFamily: {
    headings: bryantFontStack,
    label: bryantFontStack,
    bodyCopy: avenirFontStack,
    monospace: 'Courier New',
  },
  palette,
  zIndex: {
    header: 202,
    drawer: 203,
  },
  headerHeight: {
    small: rem(44),
    large: rem(64),
  },
} as const

export type ThemeOther = typeof other
