import classes from './Logo.module.css'

type Props = { color?: string }

export const Logo = ({ color }: Props) => (
  <svg className={classes.svg} viewBox="0 0 120 42" aria-label="MOO" role="img">
    <path
      style={{ fill: color ?? 'var(--mantine-palette-brand-light)' }}
      d="M50 27.45l9.2-17.8a1.62 1.62 0 0 1 1.6-1.1 1.52 1.52 0 0 1 1.6 1.6v21.5a1.6 1.6 0 0 1-1.6 1.7 1.63 1.63 0 0 1-1.7-1.7v-15l-7.3 14a2.14 2.14 0 0 1-1.8 1.1 2 2 0 0 1-1.8-1.1l-7.3-14v15a1.63 1.63 0 0 1-1.7 1.7 1.6 1.6 0 0 1-1.6-1.7v-21.4a1.47 1.47 0 0 1 1.6-1.6 1.71 1.71 0 0 1 1.6 1.1zm28.4 3a9.5 9.5 0 1 1 9.5-9.5 9.5 9.5 0 0 1-9.5 9.5m12.7-9.5a12.7 12.7 0 1 0-12.7 12.7 12.72 12.72 0 0 0 12.7-12.7m15.7 9.5a9.5 9.5 0 1 1 9.5-9.5 9.5 9.5 0 0 1-9.5 9.5m12.7-9.5a12.7 12.7 0 1 0-12.7 12.7 12.72 12.72 0 0 0 12.7-12.7m-106 15.3a9.7 9.7 0 0 0 9.8-9.8 13.55 13.55 0 0 0-.2-2c-.4-2.2-2.1-6-5-11.1-2.2-3.9-4.1-6.7-4.1-6.7l-.5-.7-.4.6s-1.9 2.9-4.1 6.7c-3 5.1-4.7 8.9-5 11.1a13.55 13.55 0 0 0-.2 2 10.06 10.06 0 0 0 2 6 9.34 9.34 0 0 0 7.7 3.9m0 3.4a12.69 12.69 0 0 1-10.3-5.1 12.6 12.6 0 0 1-2.7-7.9A10.9 10.9 0 0 1 .8 24c.5-2.9 2.6-7.6 6.4-14.1 2.8-4.8 5.1-8.4 5.2-8.4.2-.4.5-.9 1.1-.9s.9.5 1.2.9c0 0 2.4 3.6 5.2 8.4 3.8 6.5 5.9 11.2 6.4 14.1a10.9 10.9 0 0 1 .3 2.6 12.6 12.6 0 0 1-2.7 7.9 13.47 13.47 0 0 1-10.4 5.1"
    />
  </svg>
)
