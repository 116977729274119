import '@mantine/core/styles.css'
import type { AppProps } from 'next/app'
import { Layout } from '@moo/components'
import { Providers } from '@moo/providers'
import '@moo/styles/shared-styles/global.css'

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Providers>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </Providers>
  )
}
