import type { PropsWithChildren } from 'react'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { CartDrawer } from '../cart'
import cn from './Layout.module.css'

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <div className={cn.container}>
        <Header />
        <main className={cn.main}>{children}</main>
        <Footer />
      </div>

      <CartDrawer />
    </>
  )
}
