const neutralColours = {
  50: '#F9FAFB',
  100: '#F2F4F7',
  200: '#E4E7EC',
  300: '#D0D5DD',
  400: '#98A2B3',
  600: '#475467', // Default
  700: '#344054',
  800: '#1D2939',
  900: '#101828', // Main text colour
  dark: '#080808', // Brand dark colour
}

// Colour arrays from the designs to try and fit in with Mantine's colour scheme. This can then be used when setting the "color" attribute on a component.
export const colors = {
  neutral: Object.values(neutralColours) as [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ],
  brand: [
    '#ECF8F2', // Messaging
    'hotpink',
    'hotpink',
    'hotpink',
    'hotpink',
    '#009870', // MOO Green 500 (Primary shade)
    '#107c5d', // MOO Green 600
    '#107c5d', // Duplicate of above for Mantine anchor elements
    'hotpink',
    'hotpink',
  ],
  secondary: [
    '#F4F5FF', // Messaging
    'hotpink',
    'hotpink',
    'hotpink',
    'hotpink',
    '#635BFF',
    '#0570DE',
    'hotpink',
    'hotpink',
    'hotpink',
  ],
  blue: [
    '#F4F5FF', // Messaging
    'hotpink',
    'hotpink',
    'hotpink',
    'hotpink',
    '#3C4FF6',
    'hotpink',
    'hotpink',
    'hotpink',
    'hotpink',
  ],
  red: [
    '#FFF4F4', // Messaging
    'hotpink',
    'hotpink',
    'hotpink',
    'hotpink',
    '#FC4848',
    '#FC4848', // Duplicate as this is shade Mantine uses for errors
    'hotpink',
    'hotpink',
    'hotpink',
  ],
  yellow: [
    '#FFFEF4', // Messaging
    'hotpink',
    'hotpink',
    'hotpink',
    'hotpink',
    '#FFE147',
    'hotpink',
    'hotpink',
    'hotpink',
    'hotpink',
  ],
} as const

export const black = neutralColours[900]
export const white = '#fff'
export const whiteRgba = (opacity = 100) =>
  `rgba(255, 255, 255, ${opacity / 100})`
export const blackRgba = (opacity = 100) => `rgba(0, 0, 0, ${opacity / 100})`

// Palette object which can be used via the theme.other property to provide a more intuitive way of using the colours with the Figma designs
export const palette = {
  brand: {
    light: colors.brand[5],
    dark: colors.brand[6],
    darkest: neutralColours[900],
  },
  secondary: {
    light: colors.secondary[5],
    dark: colors.secondary[6],
  },
  neutral: neutralColours,
  messaging: {
    blue: colors.blue[5],
    red: colors.red[6],
    yellow: colors.yellow[5],
    lightGreen: colors.brand[0],
    lightBlue: colors.secondary[0],
    lightRed: colors.red[0],
    lightYellow: colors.yellow[0],
  },
  text: {
    default: colors.neutral[9],
    disabled: colors.neutral[4],
    moo: colors.brand[6],
    secondary: colors.neutral[6],
    white,
  },
  icons: {
    default: colors.neutral[9],
    disabled: colors.neutral[4],
    moo: colors.brand[6],
    secondary: colors.neutral[6],
    white,
  },
  borders: {
    dark: colors.neutral[7],
    light: colors.neutral[3],
    medium: colors.neutral[4],
    moo: colors.brand[0],
    white,
  },
  surface: {
    white15: whiteRgba(15),
    white50: whiteRgba(50),
    white75: whiteRgba(75),
    white: whiteRgba(100),
    cloud: colors.neutral[1],
    scrim: blackRgba(30),
    black50: blackRgba(50),
    mooGreen: 'rgba(16, 124, 93, 0.3)',
    dark: blackRgba(100),
  },
  system: {
    green: '#228403',
    amber: '#C84801',
    red: '#DF1B41',
  },
} as const

export type ThemeColors = keyof typeof colors
