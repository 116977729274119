import type { PropsWithChildren } from 'react'
import { AuthProvider } from '@moo/identity'
import { ThemeProvider } from '@moo/styles'

export const Providers = ({ children }: PropsWithChildren) => {
  return (
    <AuthProvider>
      <ThemeProvider forceColorScheme="light">{children}</ThemeProvider>
    </AuthProvider>
  )
}
