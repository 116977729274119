type Props = {
  isEmpty?: boolean
}

export const CartSVG = ({ isEmpty = true }: Props) => {
  const empty = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.25C1.58579 2.25 1.25 2.58579 1.25 3C1.25 3.41421 1.58579 3.75 2 3.75H4.90049L7.12679 13.6077C7.41015 14.8623 8.52429 15.75 9.8088 15.75H18.3957C19.6846 15.75 20.8006 14.8547 21.0801 13.5966L22.7286 6.1785C22.7363 6.14702 22.742 6.11475 22.7456 6.08185C22.7681 5.87883 22.7063 5.68197 22.5851 5.53069C22.4637 5.37955 22.2849 5.27645 22.0818 5.25441C22.049 5.25076 22.0162 5.24933 21.9838 5.25H6.77704L6.23158 2.83478C6.15435 2.49283 5.85056 2.25 5.5 2.25H2ZM7.1158 6.75L8.58993 13.2773C8.71846 13.8463 9.22367 14.25 9.8088 14.25H18.3957C18.9816 14.25 19.4888 13.8431 19.6159 13.2712L21.065 6.75H7.1158ZM10 18.75C9.58579 18.75 9.25 19.0858 9.25 19.5C9.25 19.9142 9.58579 20.25 10 20.25C10.4142 20.25 10.75 19.9142 10.75 19.5C10.75 19.0858 10.4142 18.75 10 18.75ZM7.75 19.5C7.75 18.2574 8.75736 17.25 10 17.25C11.2426 17.25 12.25 18.2574 12.25 19.5C12.25 20.7426 11.2426 21.75 10 21.75C8.75736 21.75 7.75 20.7426 7.75 19.5ZM17.25 19.5C17.25 19.0858 17.5858 18.75 18 18.75C18.4142 18.75 18.75 19.0858 18.75 19.5C18.75 19.9142 18.4142 20.25 18 20.25C17.5858 20.25 17.25 19.9142 17.25 19.5ZM18 17.25C16.7574 17.25 15.75 18.2574 15.75 19.5C15.75 20.7426 16.7574 21.75 18 21.75C19.2426 21.75 20.25 20.7426 20.25 19.5C20.25 18.2574 19.2426 17.25 18 17.25Z"
        fill="currentColor"
      />
    </svg>
  )

  const notEmpty = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.25C1.58579 2.25 1.25 2.58579 1.25 3C1.25 3.41421 1.58579 3.75 2 3.75H4.90049L7.12679 13.6077C7.41015 14.8623 8.52429 15.75 9.8088 15.75H18.3957C19.6846 15.75 20.8006 14.8547 21.0801 13.5966L22.7286 6.1785C22.7363 6.14702 22.742 6.11475 22.7456 6.08185C22.7681 5.87883 22.7063 5.68197 22.5851 5.53069C22.4637 5.37955 22.2849 5.27645 22.0818 5.25441C22.049 5.25076 22.0162 5.24933 21.9838 5.25H6.77704L6.23158 2.83478C6.15435 2.49283 5.85056 2.25 5.5 2.25H2ZM7.1158 6.75L8.58993 13.2773C8.71846 13.8463 9.22367 14.25 9.8088 14.25H18.3957C18.9816 14.25 19.4888 13.8431 19.6159 13.2712L21.065 6.75H7.1158ZM10 18.75C9.58579 18.75 9.25 19.0858 9.25 19.5C9.25 19.9142 9.58579 20.25 10 20.25C10.4142 20.25 10.75 19.9142 10.75 19.5C10.75 19.0858 10.4142 18.75 10 18.75ZM7.75 19.5C7.75 18.2574 8.75736 17.25 10 17.25C11.2426 17.25 12.25 18.2574 12.25 19.5C12.25 20.7426 11.2426 21.75 10 21.75C8.75736 21.75 7.75 20.7426 7.75 19.5ZM17.25 19.5C17.25 19.0858 17.5858 18.75 18 18.75C18.4142 18.75 18.75 19.0858 18.75 19.5C18.75 19.9142 18.4142 20.25 18 20.25C17.5858 20.25 17.25 19.9142 17.25 19.5ZM18 17.25C16.7574 17.25 15.75 18.2574 15.75 19.5C15.75 20.7426 16.7574 21.75 18 21.75C19.2426 21.75 20.25 20.7426 20.25 19.5C20.25 18.2574 19.2426 17.25 18 17.25Z"
        fill="currentColor"
      />
      <circle cx="18.5" cy="6.83203" r="5.5" fill="#009870" />
    </svg>
  )

  return isEmpty ? empty : notEmpty
}
