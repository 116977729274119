import { Anchor, Divider } from '@mantine/core'
import { IconBrandInstagram } from '@tabler/icons-react'
import { clsx } from '@moo/styles'
import { Logo } from '../Logo'
import { Text } from '../Text'
import classes from './Footer.module.css'

export const Footer = () => {
  return (
    <footer className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <div className={classes.localeAndLinksWrapper}>
          <Divider />

          <ul className={clsx(classes.linkList, classes.legalLinks)}>
            <li>
              <Anchor
                className={classes.legalLink}
                href="https://www.moo.com/us/about/terms-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Terms &amp; Conditions
              </Anchor>
            </li>
            <li>
              <Anchor
                className={classes.legalLink}
                href="https://www.moo.com/us/about/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </Anchor>
            </li>
            <li>
              <Anchor
                className={classes.legalLink}
                href="https://www.moo.com/us/about/fonts"
                target="_blank"
                rel="noreferrer"
              >
                Fonts
              </Anchor>
            </li>
            <li>
              <Anchor
                className={classes.legalLink}
                href="https://www.moo.com/us/sitemap"
                target="_blank"
                rel="noreferrer"
              >
                Sitemap
              </Anchor>
            </li>
            <li>
              <Anchor
                className={classes.legalLink}
                href="https://www.moo.com/us/about/company-information"
                target="_blank"
                rel="noreferrer"
              >
                Company Information
              </Anchor>
            </li>
          </ul>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.bottomWrapper}>
          <div className={classes.logoWrapper}>
            <Logo />
          </div>
          <Text className={classes.copyrightText} size="sm" variant="label">
            © MOO Inc., 25 Fairmount Avenue, East Providence, RI 02914, USA -
            Registered in the United States of America
          </Text>
          <ul className={clsx(classes.linkList, classes.socialLinks)}>
            <li>
              <Anchor
                aria-label="MOO on Facebook"
                href="https://www.facebook.com/moo"
                target="_blank"
                rel="noreferrer"
                c="black"
              >
                F
              </Anchor>
            </li>
            <li>
              <Anchor
                aria-label="MOO on Instagram"
                href="https://www.instagram.com/moo"
                target="_blank"
                rel="noreferrer"
                c="black"
              >
                <IconBrandInstagram />
              </Anchor>
            </li>
            <li>
              <Anchor
                aria-label="MOO on X"
                href="https://www.x.com/moo"
                target="_blank"
                rel="noreferrer"
                c="black"
              ></Anchor>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
