import { IconTrash } from '@tabler/icons-react'
import { useCartStore } from '@moo/stores'
import { Product } from '@moo/types'
import { ActionIcon } from '../../ActionIcon'
import { Flex } from '../../Flex'
import { Group } from '../../Group'
import { Image } from '../../Image'
import { Text } from '../../Text'

export type CartItemProps = Product

export const CartItem = ({
  id,
  name,
  description,
  image,
  price,
}: CartItemProps) => {
  const removeFromCart = useCartStore((state) => state.removeFromCart)

  const handleRemove = () => {
    removeFromCart(id)
  }

  return (
    <Flex
      gap="sm"
      justify="space-around"
      align="flex-start"
      bg="var(--mantine-palette-surface-cloud)"
      p="xs"
    >
      <Group>
        <Image src={image} alt={name} radius="sm" w={100} />
      </Group>
      <Group gap="xs">
        <Text>{name}</Text>
        <Text c="dimmed" size="xs">
          {description}
        </Text>
      </Group>
      <Group justify="flex-start" align="center" gap="xs">
        <Text>${price.toFixed(2)}</Text>
        <ActionIcon
          aria-label="Remove from cart"
          size="md"
          radius="xs"
          color="var(--mantine-palette-messaging-red)"
          variant="light"
          onClick={handleRemove}
        >
          <IconTrash size={18} />
        </ActionIcon>
      </Group>
    </Flex>
  )
}
