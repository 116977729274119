import Link from 'next/link'
import { env } from '@moo/environment'
import { useUser } from '@moo/identity'
import { Button } from '../Button'
import { Loader } from '../Loader'
import { Text } from '../Text'
import { UserMenu } from '../UserMenu'

export const AuthLinks = () => {
  const { user, error, isLoading } = useUser()

  if (isLoading) return <Loader size="sm" />
  if (error) return null
  if (user) return <UserMenu />

  return (
    <Button
      component={Link}
      href={`${env.NEXT_PUBLIC_MAIN_APP_URL}/api/auth/login`}
      size="sm"
      variant="light"
    >
      <Text fw={500}>Log in</Text>
    </Button>
  )
}
