import {
  MantineColorsTuple,
  MantineThemeOverride,
  createTheme,
  rem,
} from '@mantine/core'
import { black, colors, white } from './colours'
import { other } from './other'

export const theme: MantineThemeOverride = createTheme({
  /** ---------------------------------------------------------
   * Controls focus ring styles:
   * 
   * focusRing: 'auto' | 'always' | 'never';
   * auto – display focus ring only when user navigates with
   *  keyboard (default)
   * always – display focus ring when user navigates with
   *  keyboard and mouse
   * never – focus ring is always hidden (not recommended)
   * 
   * Default value: 'auto'
   --------------------------------------------------------- */
  focusRing: 'auto',

  /** ---------------------------------------------------------
   * Change focus ring styles (overrides `focusRing` setting above
   *  - ensure the ruleset in the CSS file uses :focus or :focus-visible
   * otherwise every focusable element will show a focus ring all the time!)
   --------------------------------------------------------- */
  // focusClassName: classes.focus,

  /** ---------------------------------------------------------
   * Determines whether motion based animations should be
   * disabled for users who prefer to reduce motion in their OS
   * settings
   * respectReducedMotion: boolean;
   * 
   * Default value: true
   --------------------------------------------------------- */
  respectReducedMotion: true,

  /** ---------------------------------------------------------
   * Determines whether elements that do not have pointer cursor
   * by default (checkboxes, radio, native select) should have it
   * cursorType: 'pointer' | 'default';
   * 
   * Default value: 'default'
   --------------------------------------------------------- */
  cursorType: 'pointer',

  /** ---------------------------------------------------------
   * Default border-radius used for most elements
   * defaultRadius: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;
   * 
   * Default value: 'sm'
   --------------------------------------------------------- */
  defaultRadius: 'sm',

  /** --------------------------------------------------------- 
   * White and black colors, defaults to '#fff' and '#000'
   * white: string;
   * black: string;
  --------------------------------------------------------- */
  black, // NOTE: This is what defines the default text color
  white,

  /** ---------------------------------------------------------
   * Object of arrays with 10 colors
   * colors: Record<string, Tuple<string, 10>>;
   * 
   * Default values: see https://mantine.dev/theming/colors/#default-colors
   --------------------------------------------------------- */
  colors: colors as Record<string | 'neutral', MantineColorsTuple>,

  /** ---------------------------------------------------------
   * Key of theme.colors
   * primaryColor: string
   * 
   * Default value: 'blue'
   --------------------------------------------------------- */
  primaryColor: 'brand',

  /** ---------------------------------------------------------
   * Index of color from theme.colors that is considered primary,
   * Shade type is 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
   * primaryShade: Shade | { light: Shade; dark: Shade };
   * 
   * Default value: {
      light: 6,
      dark: 8
    },
   --------------------------------------------------------- */
  primaryShade: 5,

  /** ---------------------------------------------------------
   * Default gradient used in components that support
   * `variant="gradient"` (Button, ThemeIcon, etc.) 
   * defaultGradient: { deg: number; from: MantineColor; to: MantineColor };
   * 
   * Default value: {
      "from": "blue",
      "to": "cyan",
      "deg": 45
    }
   --------------------------------------------------------- */
  // NOTE: We currently do not make heavy use of gradients, so this is not needed

  /** ---------------------------------------------------------
   * font-family used in most components
   * fontFamily: string;
   * 
   * Default fontFamily: '-apple-system, BlinkMacSystemFont,
   *  Segoe UI, Roboto, Helvetica, Arial, sans-serif,
   *  Apple Color Emoji, Segoe UI Emoji'
   --------------------------------------------------------- */
  fontFamily: other.fontFamily.bodyCopy,

  /** ---------------------------------------------------------
   * Monospace font-family, used in Code, Kbd and Prism components
   * 
   * Default value: 'ui-monospace, SFMono-Regular, Menlo, Monaco,
   *  Consolas, Liberation Mono, Courier New, monospace'
   --------------------------------------------------------- */
  fontFamilyMonospace: other.fontFamily.monospace,

  /** ---------------------------------------------------------
   * Sizes for corresponding properties
   * fontSizes: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;
   * radius: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;
   * spacing: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;
   * 
   * Default values:
   * {
      xs: rem(12),
      sm: rem(14),
      md: rem(16),
      lg: rem(18),
      xl: rem(20),
    }
   * radius: {
      xs: rem(2),
      sm: rem(4),
      md: rem(8),
      lg: rem(16),
      xl: rem(32),
    }
   * spacing: {
      xs: rem(10),
      sm: rem(12),
      md: rem(16),
      lg: rem(20),
      xl: rem(32),
    }
   --------------------------------------------------------- */

  // NOTE: Explicitly setting fontSizes to ensure any future
  // changes to the Mantine defaults do not affect us
  fontSizes: {
    xs: rem(12),
    sm: rem(14),
    md: rem(16),
    lg: rem(20),
    xl: rem(24),

    // NOTE: These are MOO custom font sizes and not part of the Mantine defaults
    // See the link below for more info
    // https://www.figma.com/file/IvTJOHChpktRn79HcloFMp/Design-system-1.0?type=design&node-id=4-59&t=VUp00RrZnBqb0w7x-0
    h1Large: rem(57),
    h1Medium: rem(45),
    h1Small: rem(36),
    h2Large: rem(32),
    h2Medium: rem(28),
    h2Small: rem(24),
    h3Large: rem(26),
    h3Medium: rem(24),
    h3Small: rem(20),
    titleLarge: rem(20),
    titleMedium: rem(18),
    titleSmall: rem(16),
    bodyLarge: rem(16),
    bodyMedium: rem(14),
    bodySmall: rem(12),
    labelLarge: rem(16),
    labelMedium: rem(14),
    labelSmall: rem(12),
    caption: rem(10),
  },

  lineHeights: {
    h1Large: '64px',
    h1Medium: '52px',
    h1Small: '44px',
    h2Large: '40px',
    h2Medium: '36px',
    h2Small: '32px',
    h3Large: '34px',
    h3Medium: '32px',
    h3Small: '26px',
    titleLarge: '24px',
    titleMedium: '22px',
    titleSmall: '19px',
    bodyLarge: '24px',
    bodyMedium: '19px',
    bodySmall: '16px',
    labelLarge: 'normal',
    labelMedium: 'normal',
    labelSmall: 'normal',
    caption: '13.66px',
  },

  // New Spacing values based on the design system found here:
  // https://www.figma.com/file/IvTJOHChpktRn79HcloFMp/Design-system-1.0?type=design&node-id=276-1240&t=ynTGjE71QVbztwXd-0
  spacing: {
    xxs: rem(4),
    xs: rem(8),
    sm: rem(16),
    md: rem(24),
    lg: rem(32),
    xl: rem(40),
    xxl: rem(48),
    xxxl: rem(64),
    xxxxl: rem(128),
  },

  /** ---------------------------------------------------------
   * Values used for box-shadow
   * shadows: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', string>;
   * 
   * Default value: {
      "xs": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), 0 calc(0.0625rem * var(--mantine-scale)) calc(0.125rem * var(--mantine-scale)) rgba(0, 0, 0, 0.1)",
      "sm": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(0.625rem * var(--mantine-scale)) calc(0.9375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.4375rem * var(--mantine-scale)) calc(0.4375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))",
      "md": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(1.25rem * var(--mantine-scale)) calc(1.5625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.625rem * var(--mantine-scale)) calc(0.625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))",
      "lg": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(1.75rem * var(--mantine-scale)) calc(1.4375rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.75rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))",
      "xl": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(2.25rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(1.0625rem * var(--mantine-scale)) calc(1.0625rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))"
    },
   --------------------------------------------------------- */
  shadows: {
    depth4:
      '-5px -5px 10px 0 rgb(249, 250, 251), 5px 5px 10px 0 rgba(152, 162, 179, 0.4)',
  },

  /** ---------------------------------------------------------
   * Breakpoints used in some components to add responsive styles
   * breakpoints: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;
   * 
   * Default value: {
      xs: '30em',
      sm: '48em',
      md: '62em',
      lg: '75em',
      xl: '88em',
    }
   --------------------------------------------------------- */
  // NOTE: These are also set for use in CSS modules in postcss.config.js
  // - if you update these values, please also update them there too!
  breakpoints: {
    xs: '30em', // 16px * 30 = 480px
    sm: '48em', // 16px * 48 = 768px
    md: '64em', // 16px * 64 = 1024px
    lg: '74em', // 16px * 74 = 1184px
    xl: '90em', // 16px * 90 = 1440px
  },

  /** ---------------------------------------------------------
   * h1-h6 styles, used in Title and TypographyStylesProvider components
   * Default value: {
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
            Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
        fontWeight: 700,
        sizes: {
          h1: { fontSize: 34, lineHeight: 1.3, fontWeight: void 0 },
          h2: { fontSize: 26, lineHeight: 1.35, fontWeight: void 0 },
          h3: { fontSize: 22, lineHeight: 1.4, fontWeight: void 0 },
          h4: { fontSize: 18, lineHeight: 1.45, fontWeight: void 0 },
          h5: { fontSize: 16, lineHeight: 1.5, fontWeight: void 0 },
          h6: { fontSize: 14, lineHeight: 1.5, fontWeight: void 0 }
        }
      }
  --------------------------------------------------------- */
  headings: {
    fontFamily: other.fontFamily.headings,
    fontWeight: 'bold',
    sizes: {
      h1: {
        fontSize: '57px',
        lineHeight: '1.3',
        fontWeight: '500',
      },
      h2: { fontSize: '1.625rem', lineHeight: '1.35', fontWeight: '500' },
      h3: { fontSize: '1.375rem', lineHeight: '1.4', fontWeight: 'bold' },
      h4: { fontSize: '1.125rem', lineHeight: '1.45', fontWeight: 'normal' },
      h5: { fontSize: '1rem', lineHeight: '1.5', fontWeight: 'normal' },
      h6: { fontSize: '0.875rem', lineHeight: '1.5', fontWeight: 'normal' },
    },
  },

  /** ---------------------------------------------------------
   * defaultProps, styles and classNames for components
   * see: https://mantine.dev/theming/default-props/
   * components: ComponentsOverride;
   * 
   * Default value: {}
   * Example:
   *  Button: {
        defaultProps: {
          size: 'xs',
          color: 'cyan',
        },
      },
   --------------------------------------------------------- */
  components: {
    Loader: {
      defaultProps: {
        size: 'xl',
        type: 'dots',
      },
    },
    Container: {
      defaultProps: {},
    },
    ColorSwatch: {
      defaultProps: {
        size: 32,
        withShadow: true,
      },
    },
    Button: {
      defaultProps: {
        radius: 'sm',
      },
    },
    ActionIcon: {
      defaultProps: {
        radius: 'xl',
      },
    },
    Table: {
      defaultProps: {
        horizontalSpacing: 'sm',
        verticalSpacing: 'xs',
      },
    },
    Divider: {
      defaultProps: {
        color: colors.neutral[3],
      },
    },
    Anchor: {
      defaultProps: {
        style: {
          fontSize: 'inherit',
        },
      },
    },
    Radio: {
      defaultProps: {
        size: 'xs',
      },
    },
    Checkbox: {
      defaultProps: {
        size: 'xs',
      },
    },
  },

  /** ---------------------------------------------------------
   * Add your own custom properties on Mantine theme
   * other: Record<string, any>;
   * 
   * Example:
   * other: {
      foo: 'bar'
    }
   --------------------------------------------------------- */
  other,
})
