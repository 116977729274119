import {
  CSSVariablesResolver,
  MantineProvider,
  type MantineProviderProps,
  MantineTheme,
} from '@mantine/core'
import { palette } from './colours'
import { other } from './other'
import { theme } from './theme'

type ThemeProviderProps = {
  forceColorScheme?: MantineProviderProps['forceColorScheme']
  children: MantineProviderProps['children']
}

// This is temporary code to help with the migration to Mantine v7
// When we update, we should use https://v7.mantine.dev/styles/css-variables#css-variables-resolver instead
const toCSSVars = (prefix: string, props: Record<string, string | number>) =>
  Object.entries(props).reduce(
    (acc, [key, value]) => ({ ...acc, [`--mantine-${prefix}-${key}`]: value }),
    {}
  )

const paletteVariables = Object.entries(palette).reduce((accGroup, group) => {
  const [groupKey, groupValues] = group

  const groupVariables = Object.entries(groupValues).reduce((acc, values) => {
    const [key, value] = values

    const CSSKey = `--mantine-palette-${groupKey}-${key}`
    const CSSValue = value.includes('#') ? value.toUpperCase() : value

    return { ...acc, [CSSKey]: CSSValue }
  }, {})

  return { ...accGroup, ...groupVariables }
}, {})

const cssVariablesResolver: CSSVariablesResolver = () => ({
  variables: {
    ...paletteVariables,
    ...toCSSVars('header-height', other.headerHeight),
    ...toCSSVars('font-family', other.fontFamily),
    ...toCSSVars(
      'line-height',
      theme.lineHeights as MantineTheme['lineHeights']
    ),
    ...toCSSVars('z-index', other.zIndex),
  },
  light: {},
  dark: {},
})

export const ThemeProvider = ({
  children,
  forceColorScheme,
}: ThemeProviderProps) => {
  return (
    <MantineProvider
      forceColorScheme={forceColorScheme}
      theme={theme}
      cssVariablesResolver={cssVariablesResolver}
    >
      {children}
    </MantineProvider>
  )
}
