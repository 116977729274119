import { useCartStore } from '@moo/stores'
import { Center } from '../../Center'
import { Flex } from '../../Flex'
import { UnstyledButton } from '../../UnstyledButton'
import { CartSVG } from './CartSVG'

export const CartIcon = () => {
  const { products, openDrawer } = useCartStore()

  const count = products.length

  return (
    <Flex direction="column" align="center" justify="center">
      <UnstyledButton
        aria-label={`Cart - ${count} item${count === 1 ? '' : 's'}`}
        aria-live="polite"
        onClick={openDrawer}
      >
        <Center>
          <CartSVG isEmpty={!count || count === 0} />
        </Center>
      </UnstyledButton>
    </Flex>
  )
}
