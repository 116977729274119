import { useCartStore } from '@moo/stores'
import { Drawer } from '../../Drawer'
import { Group } from '../../Group'
import cn from './CartDrawer.module.css'
import { CartItem } from './CartItem'

export const CartDrawer = () => {
  const { products, closeDrawer, drawerOpen } = useCartStore()

  return (
    <Drawer
      opened={drawerOpen}
      onClose={closeDrawer}
      title="Your cart:"
      position="right"
      size="xl"
      classNames={{
        inner: cn.inner,
      }}
    >
      <Group>
        {products.map((product) => (
          <CartItem key={product.id} {...product} />
        ))}
      </Group>
    </Drawer>
  )
}
