import { Divider } from '@mantine/core'
import Link from 'next/link'
import { env } from '@moo/environment'
import { Badge } from '../Badge'
import { Flex } from '../Flex'
import { Logo } from '../Logo'
import { CartIcon } from '../cart'
import { AuthLinks } from './AuthLinks'
import cn from './Header.module.css'

export const Header = () => {
  return (
    <header className={cn.header}>
      <div className={cn.logo__wrapper}>
        <Logo />
      </div>

      <nav className={cn.nav}>
        <div className={cn.nav__item}>
          <Badge radius="sm" color="var(--mantine-palette-brand-light)" mr="sm">
            Beta
          </Badge>
          <Link
            href={`${env.NEXT_PUBLIC_MAIN_APP_URL}/products`}
            className={cn.nav__link}
          >
            Custom Products
          </Link>
        </div>
        <div className={cn.nav__item}>
          <a
            className={cn.nav__link}
            href="https://www.moo.com/us/business-cards"
            target="_blank"
            rel="noopener noreferrer"
          >
            Business Cards
          </a>
        </div>
        <div className={cn.nav__item}>
          <a
            className={cn.nav__link}
            href="https://www.moo.com/us/postcards"
            target="_blank"
            rel="noopener noreferrer"
          >
            Postcards
          </a>
        </div>
      </nav>

      <Flex ml="auto" align="center">
        <AuthLinks />
        <Divider orientation="vertical" mx="xs" />
        <CartIcon />
      </Flex>
    </header>
  )
}
